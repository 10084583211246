
import React from 'react'
import { Link } from 'gatsby'
import GoogleMapReact from 'google-map-react';
import './styles/index.sass'

import hamburgerIcon from '../../../static/img/fastFood.png';
import pizzaIcon from '../../../static/img/pizzaIcon.png';
import mexicanFoodIcon from '../../../static/img/tacoIcon.png';
import sushiIcon from '../../../static/img/sushiIcon.png';
import tikkaIcon from '../../../static/img/tikka.png';
import carrotIcon from '../../../static/img/carrotIcon.png';
import bowlIcon from '../../../static/img/bowlIcon.png';
import unknownIcon from '../../../static/img/unknownIcon.png';
import closeIcon from '../../../static/img/closeIcon.png';
import bazookaIcon from '../../../static/img/bazookaIcon.png';
import falafelIcon from '../../../static/img/falafelIcon.png';

const Marker = ({children, ...props}) => {
  return (
    <div {...props}>
      {children}
    </div>
  );
}

const Map = class extends React.Component {
  static defaultProps = {
    center: {
      lat: 59.318286,
      lng: 18.075884
    },
    zoom: 17
  };

  state = {
    // markers:[{
    //   position:{
    //     lat: 59.318100,
    //     lng:18.072422,
    //   }
    // }],

    posts: [],
    requestedPosition: {
      lat: 59.318286,
      lng: 18.075884
    }
  }

  // componentDidMount() {
  //   this.google = window.google; 
  // }

  apiLoaded = () => {
    console.log("api loaded!")
  }

  getMarkerIcon = (category) => {
    category = category.toLowerCase(); 

    switch(category) {
        case 'asiatiskt': return sushiIcon
        case 'sushi': return sushiIcon
        case 'pizza': return pizzaIcon
        case 'falafel': return falafelIcon
        case 'soup': 
        case 'ramen': 
        case 'pokebowl':
            return bowlIcon
        case 'hamburgare': 
        case 'snabbmat': 
            return hamburgerIcon
        case 'husmanskost': return unknownIcon
        case 'mexikanskt': 
        case 'tacos':
            return mexicanFoodIcon
        case 'indiskt': return tikkaIcon
        case 'vegetariskt': return carrotIcon
        case 'blandat': return unknownIcon
        default:
            return unknownIcon
    } 
  }

  componentDidMount() {
      if(window.location.search){
        var queryStr = window.location.search,
          queryArr = queryStr.replace('?','').split('&'),
          queryParams = [];

        for (var q = 0, qArrLength = queryArr.length; q < qArrLength; q++) {
            var qArr = queryArr[q].split('=');
            queryParams[qArr[0]] = qArr[1];
        }
        // Converting query parameters from string to number
        this.setState({ requestedPosition: { lat: +queryParams.lat, lng: +queryParams.lng } })
      }
      // else{
      //   this.setState({ requestedPosition: { lat: 59.318286, lng: 18.075884 } })
      // }

      this.setState({ posts: this.props.posts }, () => {
        // console.log(this.state.posts);
      });


  }
 


  render() {

    return (
      // Important! Always set the container height explicitly
      <React.Fragment>

        <div class="map__close-text">
          <Link to="/">
            Stäng karta   <img src={closeIcon} className="map__close-icon" alt="Close icon" />
          </Link>
        </div>
    


        {/* <div class="map__close-text" onClick={() => this.props.setView('list')}>
          Stäng karta   <img src={closeIcon} className="map__close-icon" alt="Close icon" />
        </div> */}
        <div className="map__container" style={{ height: '600px', width: 'calc(100% - 40px)'  }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyCfUTgHCEg5cKAQo8wmrjUGrti5s1tB4LI', libraries: 'geometry' }}
            // defaultCenter={this.props.center}
            center={this.state.requestedPosition}
            defaultZoom={this.props.zoom}
            // onGoogleApiLoaded={this.apiLoaded}
          >

           <div className="unikt" lat={59.318286} lng={18.075884}>
            <img src={bazookaIcon} className="map__bazooka-icon" alt="Bazooka Icon" />
          </div> 


          {this.state.posts.map(marker => {
            return <Marker 
              key={marker.node.id} 
              className="unikt" 
              lat={marker.node.frontmatter.position != null ? marker.node.frontmatter.position[0] : ''} 
              lng={marker.node.frontmatter.position != null ? marker.node.frontmatter.position[1] : ''} 

              // icon={{
              //   url: foodIcon,
              //   size: '10px'
              //   // scaledSize: 5
              // }}
              // icon={this.getMarkerIcon(marker.node.frontmatter.category)}

            >

            {console.log(marker.node.frontmatter.categories)
            
          }

          {marker.node.frontmatter.categories != null ? <img src={this.getMarkerIcon(marker.node.frontmatter.categories[0])} className="map__marker-icon"  alt="Marker Icon" /> : ''}



            {/* {marker.node.frontmatter.categories.length > 0 ? console.log(marker.node.frontmatter.categories[0]) : console.log("nej")} */}
              {/* <img src={this.getMarkerIcon(marker.node.frontmatter.categories[0])} className="map__marker-icon"  alt="Marker Icon" /> */}
              {marker.node.frontmatter.title}
            </Marker> 

          }
        )}
          


        
          </GoogleMapReact>
        </div>
      </React.Fragment>
    );
  }
}


export default Map;

