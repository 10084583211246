import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Map from '../components/Map'

const MapsPage = ({ data }) => {
  // const { markdownRemark: post } = data

  console.log('[MapsPage]', data);

  return (
    <Layout>

      <Map posts={data.allMarkdownRemark.edges} />

    </Layout>
  )

}

MapsPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default MapsPage

export const mapsPageQuery = graphql`
query MapsQuery {
  allMarkdownRemark(limit: 1000) {
    edges {
      node {
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          description
          categories
          tags
          address
          title
          position
        }
      }
    }
  }
}
`

// query MapsQuery {
//   site {
//     siteMetadata {
//       title
//     }
//   }
//   allMarkdownRemark(limit: 1000) {
//     edges {
//       node {
//         frontmatter {
//           date(formatString: "MMMM DD, YYYY")
//           description
//           categories
//           tags
//           address
//           title
//           position
//         }
//       }
//     }
//   }
// }
// `